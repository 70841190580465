<template>
  <v-form ref="loginForm">
    <v-card max-width="450" class="ma-auto" elevation="0">
      <v-card-title class="mb-4">
        <div class="d-flex ma-auto font-weight-light text-h4">
          <v-img
            :src="siteLogoUrl"
            width="40"
            height="40"
            class="mr-4"
          ></v-img>

          <v-spacer></v-spacer>
          <span>{{ 'action.login'| t }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          outlined
          autofocus
          @keyup.enter="$helper.focus('.password input', $el)"
          class="email"
          :label="$t('data.email')"
          :rules="[
            value => $helper.validator.requiredRule(value),
            value => $helper.validator.emailRule(value),
          ]"
          v-model="data.email"
        ></v-text-field>

        <eagle-password
          @keyupEnter="login"
          inputClass="password"
          :rules="[
            value => $helper.validator.requiredRule(value),
          ]"
          v-model="data.password"
        ></eagle-password>

        <div class="d-flex justify-space-between">
          <v-checkbox
            class="mb-4"
            dense
            :label="$t('keep.login')"
            :messages="$t('keep.login.help')"
            v-model="keepLogin"
          ></v-checkbox>

          <v-checkbox
            class="mb-4"
            dense
            :label="$t('remember.email')"
            :messages="$t('remember.email.help')"
            v-model="rememberEmail"
          ></v-checkbox>
        </div>

        <v-btn
          block
          color="primary"
          @click="login"
          :loading="loading"
          :disabled="disabled"
        >
          {{'action.login'| t}}
        </v-btn>

        <p class="text--grey font-weight-light caption">
          {{'fill.data.and.send_it_out'| t}}
        </p>

        <router-link :to="{ name: 'send-reset-password-email' }" class="text-decoration-none">
          {{'action.forgot-password'| t}}
        </router-link>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script lang="babel" type="text/babel">
import {mapGetters} from 'vuex'
export default {
  metaInfo() {
    return {
      title: this.$t('action.login'),
    }
  },
  data: () => ({
    data: {
      email: null,
      password: null,
      recaptcha: null,
    },
    rememberEmail: false,
    keepLogin: false,
    loading: false,
    storageProfile: null,
  }),
  beforeDestroy() {
    this.$recaptchaLibrary.destroy()
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.$recaptchaLibrary.load()
      this.storageProfile = this.$helper.account.getStorageAccountProfile('admin')
      if(this.storageProfile) {
        this.data.email = this.storageProfile.email
        this.rememberEmail = true
        await this.$nextTick()
        await this.$helper.delay(0.3)
        this.$helper.focus('.password input', this.$el)
      }
    },
    async login() {
      if(this.loading) return
      if(this.disabled) return
      this.loading = true

      await this.$recaptchaLibrary.execute(token => {
        this.data.recaptcha = token
      })

      try {
        const result = await this.$api.collection.siteAdminAccountApi.login(this.data, this.keepLogin)
        this.$tokenStore.set(result.token)
        this.$helper.account.login(this.keepLogin, this.rememberEmail)
      } catch (error) {
        console.error(error)
        const errorStatus = error.status
        const title = this.$t('notify.login.failure')
        let content = this.$t('error.unexpect')
        if(errorStatus == 400 || errorStatus == 403) {
          content = this.$t('notify.login.failure.incorrect')
        }

        if(errorStatus == 406) {
          content = this.$t(`notify.login.failure.iso_27001_lock`, {
            time: error.data.lock_login_until
          })
        }

        else if(errorStatus == 431) {
          content = this.$t('notify.recaptcha.failure')
        }

        else if(errorStatus == 409) {
          content = this.$t('notify.login.failure.locked')
        }
        this.$snotify.error(content, title)
      } finally {
        this.loading = false
        if(!this.rememberEmail) {
          this.$helper.account.cleanStorageAccountProfile('admin')
        }
      }
    }
  },
  computed: {
    storageProfileAvatar() {
      if(!this.storageProfile) return null
      return this.storageProfile.avatar
    },
    ...mapGetters({
      siteLogoUrl: 'base/siteLogoUrl',
    }),
    disabled() {
      if(!this.$helper.validator.required(this.data.email)) return true
      if(!this.$helper.validator.email(this.data.email)) return true
      if(!this.$helper.validator.required(this.data.password)) return true
      return false
    },
    tokenData() {
      return this.$tokenStore.data
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
